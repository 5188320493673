.home {
    position: relative;
    height: 100vh;
    width: 100vw;
    margin: 0;
    overflow: hidden;
    font-size: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    z-index: -1; 
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
}

.content {
    position: relative;
    z-index: 1; 
    max-width: 80%; 
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1); 
    border-radius: 15px; 
    font-family: 'Arial', sans-serif;
    font-size: 1.2rem; 
    color: #ffffff; 
    text-align: left;
}

.first-line {
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
}

.last-line {
    text-align: left;
    width: 100%;
    margin-top: 20px;
}

a {
    color: #e470aa;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
